@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  cursor: pointer;
}

.App-Mui-DataGrid-Header {
  background-color: #f5f7fa;
  color: #909098;
}
.App-Mui-DataGrid-Header .MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

.query-builder-container .group {
  background-color: #eff1f9;
  border: 1px dashed #d6dae1;
}

.query-builder-container
  .qb-lite
  .group--header:hover
  .group--header
  .group--drag-handler,
.query-builder-container
  .qb-lite
  .group--header:hover
  .group--header
  .group--actions,
.query-builder-container
  .qb-lite
  .group--header:not(:hover)
  .group--drag-handler,
.query-builder-container .qb-lite .group--header:not(:hover) .group--actions,
.query-builder-container .qb-lite .rule_group:not(:hover) .group--drag-handler,
.query-builder-container .qb-lite .rule_group:not(:hover) .group--actions {
  opacity: 1;
}

.query-builder-container .qb-lite .group--actions--tr,
.query-builder-container .qb-lite .group--actions--br {
  justify-content: flex-start;
}

.query-builder-container .qb-lite .rule--field .MuiInput-root {
  min-width: 300px;
}

.query-builder-container .qb-lite .rule--operator {
  margin-left: 110px;
}

.MuiDataGrid-panelFooter button:last-child {
  display: none;
}
